import React, { useState, useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTeam } from '../context/TeamContext';
import { useAuth } from '../context/AuthContext';
import './GeneratedRotaPage.css';

function GeneratedRotaPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const { generatedRota: initialRota, selectedDates, selectedAlgorithm } = location.state || {};
  const [rota, setRota] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [rotaName, setRotaName] = useState('');
  const [showAssignmentCounts, setShowAssignmentCounts] = useState(false);
  const [assignmentCounts, setAssignmentCounts] = useState({});
  const [conflicts, setConflicts] = useState([]);
  const [showConflicts, setShowConflicts] = useState(false);
  const { teamMembers, saveRota } = useTeam();
  const { user } = useAuth();

  const positions = ['Jimmy Jib', 'Selatan', 'Utara', 'Switcher', 'Title 1', 'Title 2', 'Animasi', 'Foto', 'Socmed'];
  const eventOrder = ['IBR1', 'IBR2', 'IBR3', 'IBR4', 'NOW', 'WBI', 'Wedding', 'Special Events'];

  useEffect(() => {
    if (initialRota) {
      // Ensure all positions exist in the rota, including Socmed
      const updatedRota = { ...initialRota };
      Object.keys(updatedRota).forEach(eventKey => {
        if (!updatedRota[eventKey]['Socmed']) {
          updatedRota[eventKey]['Socmed'] = 'Unassigned';
        }
      });
      setRota(updatedRota);
      calculateAssignmentCounts(updatedRota);
    }
  }, [initialRota]);

  const groupedRota = useMemo(() => {
    if (!rota) return {};
    return Object.entries(rota).reduce((acc, [eventKey, assignments]) => {
      const [date, event] = eventKey.split(' ');
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push({ event, assignments });
      return acc;
    }, {});
  }, [rota]);

  const calculateAssignmentCounts = (rotaData) => {
    const counts = {};
    Object.values(rotaData).forEach(assignments => {
      Object.values(assignments).forEach(name => {
        if (name !== 'Unassigned') {
          counts[name] = (counts[name] || 0) + 1;
        }
      });
    });
    setAssignmentCounts(counts);
  };

  const handleCellEdit = (eventKey, position, value) => {
    setRota(prevRota => {
      const newRota = {
        ...prevRota,
        [eventKey]: {
          ...prevRota[eventKey],
          [position]: value
        }
      };
      calculateAssignmentCounts(newRota);
      return newRota;
    });
  };

  const getQualifiedMembers = (position) => {
    const qualificationMap = {
      'Jimmy Jib': 'JimmyJib',
      'Selatan': 'Moving',
      'Utara': 'Moving',
      'Switcher': 'Switcher',
      'Title 1': 'Title',
      'Title 2': 'Title',
      'Animasi': 'Animasi',
      'Foto': 'Foto',
      'Socmed': 'Socmed'
    };
    const qualification = qualificationMap[position];
    return teamMembers.filter(member => member.qualifications[qualification] && member.active);
  };

  const exportToCSV = () => {
    if (!rota) return;

    // Headers
    let csvContent = 'Date,Event,' + positions.join(',') + '\n';

    // Data rows
    Object.entries(groupedRota).forEach(([date, events]) => {
      events.forEach(({ event, assignments }) => {
        const row = [
          date,
          event,
          ...positions.map(pos => assignments[pos] || 'Unassigned')
        ];
        csvContent += row.join(',') + '\n';
      });
    });

    // Create and trigger download
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', `${rotaName || 'rota'}.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const checkForConflicts = () => {
    const conflictList = [];
    Object.entries(rota).forEach(([eventKey, assignments]) => {
      const assignedNames = new Set();
      const conflictingNames = new Set();

      Object.entries(assignments).forEach(([position, name]) => {
        if (name !== 'Unassigned') {
          if (assignedNames.has(name)) {
            conflictingNames.add(name);
          } else {
            assignedNames.add(name);
          }
        }
      });

      if (conflictingNames.size > 0) {
        conflictList.push({
          event: eventKey,
          names: Array.from(conflictingNames)
        });
      }
    });

    setConflicts(conflictList);
    setShowConflicts(true);
    return conflictList.length === 0;
  };

  const handleSaveRota = async () => {
    if (rotaName.trim() === '') {
      alert('Please enter a name for the rota.');
      return;
    }

    const noConflicts = checkForConflicts();
    if (!noConflicts) {
      alert('There are conflicts in the rota. Please resolve them before saving.');
      return;
    }

    setIsSaving(true);
    try {
      await saveRota(rota, rotaName, user.email);
      alert('Rota saved successfully!');
      navigate('/schedules');
    } catch (error) {
      console.error('Error saving rota:', error);
      alert(`Failed to save rota. Error: ${error.message}`);
    } finally {
      setIsSaving(false);
    }
  };

  const toggleAssignmentCounts = () => {
    setShowAssignmentCounts(!showAssignmentCounts);
  };

  const handleRegenerate = () => {
    navigate('/create-rota', { 
      state: { 
        selectedDates: selectedDates.map(date => new Date(date).toISOString()),
        selectedAlgorithm: selectedAlgorithm,
        isRegenerating: true 
      },
      replace: true
    });
  };

  if (!rota) {
    return <div className="loading">Loading rota data...</div>;
  }

  return (
    <div className="generated-rota-page">
      <h1>Generated Rota</h1>
      <div className="rota-table-container">
        <table className="rota-table">
          <thead>
            <tr>
              <th className="date-header">Date</th>
              <th className="event-header">Event</th>
              {positions.map(position => (
                <th key={position} className="position-header">{position}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {Object.entries(groupedRota).map(([date, events], dateIndex, datesArray) => (
              events.map(({ event, assignments }, eventIndex) => (
                <tr key={`${date}-${event}`} className={eventIndex === 0 ? 'new-date' : ''}>
                  {eventIndex === 0 && <td rowSpan={events.length} className="date-cell">{date}</td>}
                  <td className="event-cell">{event}</td>
                  {positions.map(position => (
                    <td key={`${date}-${event}-${position}`} className="assignment-cell">
                      <select
                        value={assignments[position] || 'Unassigned'}
                        onChange={(e) => handleCellEdit(`${date} ${event}`, position, e.target.value)}
                      >
                        <option value="Unassigned">Unassigned</option>
                        {getQualifiedMembers(position).map(member => (
                          <option key={member.id} value={member.name}>{member.name}</option>
                        ))}
                      </select>
                    </td>
                  ))}
                </tr>
              ))
            ))}
          </tbody>
        </table>
      </div>
      <div className="rota-actions">
        <div className="action-buttons">
          <button onClick={toggleAssignmentCounts} className="action-button count-button">
            Count Assignments
          </button>
          <button onClick={checkForConflicts} className="action-button conflict-button">
            Check Conflicts
          </button>
          <button onClick={handleRegenerate} className="action-button regenerate-button">
            Regenerate
          </button>
        </div>
        <div className="final-actions">
          <input
            type="text"
            placeholder="Enter rota name"
            value={rotaName}
            onChange={(e) => setRotaName(e.target.value)}
            className="rota-name-input"
          />
          <button onClick={exportToCSV} className="action-button export-button">
            Export to CSV
          </button>
          <button
            onClick={handleSaveRota}
            className="action-button save-button"
            disabled={isSaving}
          >
            {isSaving ? 'Saving...' : 'Save Rota'}
          </button>
        </div>
      </div>

      {showAssignmentCounts && (
        <div className="assignment-counts-popup">
          <div className="assignment-counts-content">
            <h2>Assignment Counts</h2>
            <ul>
              {Object.entries(assignmentCounts)
                .sort((a, b) => b[1] - a[1])
                .map(([name, count]) => (
                  <li key={name}>{name}: {count}</li>
                ))}
            </ul>
            <button onClick={toggleAssignmentCounts}>Close</button>
          </div>
        </div>
      )}

      {showConflicts && (
        <div className="conflicts-popup">
          <div className="conflicts-content">
            <h2>Conflicts Found</h2>
            {conflicts.length === 0 ? (
              <p>No conflicts found in the rota.</p>
            ) : (
              <ul>
                {conflicts.map((conflict, index) => (
                  <li key={index}>
                    {conflict.event}: {conflict.names.join(', ')}
                  </li>
                ))}
              </ul>
            )}
            <button onClick={() => setShowConflicts(false)}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
}

export default GeneratedRotaPage;