import React, { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { db } from '../firebase';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { useTeam } from '../context/TeamContext';
import './ViewRotaPage.css';

function ViewRotaPage() {
  const [rota, setRota] = useState(null);
  const [rotaName, setRotaName] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [editedRota, setEditedRota] = useState(null);
  const [assignmentCounts, setAssignmentCounts] = useState({});
  const [showAssignmentCounts, setShowAssignmentCounts] = useState(false);
  const [conflicts, setConflicts] = useState([]);
  const [showConflicts, setShowConflicts] = useState(false);
  const { id } = useParams();
  const { teamMembers } = useTeam();

  const positions = ['Jimmy Jib', 'Selatan', 'Utara', 'Switcher', 'Title 1', 'Title 2', 'Animasi', 'Foto', 'Socmed'];
  const eventOrder = ['IBR1', 'IBR2', 'IBR3', 'IBR4', 'NOW', 'WBI', 'Wedding'];

  useEffect(() => {
    const fetchRota = async () => {
      const rotaRef = doc(db, 'rotas', id);
      const snapshot = await getDoc(rotaRef);
      if (snapshot.exists()) {
        const rotaData = snapshot.data();
        setRotaName(rotaData.name);
        setRota(rotaData.rota);
        setEditedRota(rotaData.rota);
        calculateAssignmentCounts(rotaData.rota);
      } else {
        console.error("Rota not found");
      }
    };

    fetchRota();
  }, [id]);

  const formatDate = (dateString) => {
    const [year, month, day] = dateString.split('-');
    return `${day}-${month}-${year}`;
  };

  const groupedAndSortedRota = useMemo(() => {
    if (!rota) return [];

    const entries = Object.entries(rota);
    
    const sortedEvents = entries.sort(([keyA], [keyB]) => {
      const [dateA, eventA] = keyA.split(' ');
      const [dateB, eventB] = keyB.split(' ');
      return dateA.localeCompare(dateB) || eventOrder.indexOf(eventA) - eventOrder.indexOf(eventB);
    });

    return sortedEvents.map(([key, assignments]) => {
      const [date, event] = key.split(' ');
      return { 
        date, 
        event, 
        assignments
      };
    });
  }, [rota, eventOrder]);

  const calculateAssignmentCounts = (rotaData) => {
    const counts = {};
    Object.values(rotaData).forEach(assignments => {
      Object.values(assignments).forEach(name => {
        if (name && name !== '-') {
          counts[name] = (counts[name] || 0) + 1;
        }
      });
    });
    setAssignmentCounts(counts);
  };

  const getQualifiedMembers = (position) => {
    const qualificationMap = {
      'Jimmy Jib': 'JimmyJib',
      'Selatan': 'Moving',
      'Utara': 'Moving',
      'Switcher': 'Switcher',
      'Title 1': 'Title',
      'Title 2': 'Title',
      'Animasi': 'Animasi',
      'Foto': 'Foto',
      'Socmed': 'Socmed'
    };
    const qualification = qualificationMap[position];
    return teamMembers.filter(member => member.qualifications[qualification]);
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const checkForConflicts = () => {
    const conflictList = [];
    Object.entries(editedRota).forEach(([eventKey, assignments]) => {
      const assignedNames = new Set();
      const conflictingNames = new Set();

      Object.entries(assignments).forEach(([position, name]) => {
        if (name && name !== '-') {
          if (assignedNames.has(name)) {
            conflictingNames.add(name);
          } else {
            assignedNames.add(name);
          }
        }
      });

      if (conflictingNames.size > 0) {
        conflictList.push({
          event: eventKey,
          names: Array.from(conflictingNames)
        });
      }
    });

    setConflicts(conflictList);
    setShowConflicts(true);
    return conflictList.length === 0;
  };

  const handleSave = async () => {
    const noConflicts = checkForConflicts();
    if (!noConflicts) {
      alert('There are conflicts in the rota. Please resolve them before saving.');
      return;
    }

    try {
      const rotaRef = doc(db, 'rotas', id);
      await updateDoc(rotaRef, { rota: editedRota });
      setRota(editedRota);
      setIsEditing(false);
      calculateAssignmentCounts(editedRota);
      alert('Rota updated successfully!');
    } catch (error) {
      console.error('Error updating rota:', error);
      alert('Failed to update rota. Please try again.');
    }
  };

  const handleCancel = () => {
    setEditedRota(rota);
    setIsEditing(false);
  };

  const handleAssignmentChange = (date, event, position, value) => {
    setEditedRota(prevRota => {
      const newRota = { ...prevRota };
      if (!newRota[`${date} ${event}`]) {
        newRota[`${date} ${event}`] = {};
      }
      newRota[`${date} ${event}`][position] = value;
      return newRota;
    });
  };

  const exportToCSV = () => {
    if (!rota) return;

    const csvContent = [
      ['Date', 'Event', ...positions].join(','),
      ...groupedAndSortedRota.map(({ date, event, assignments }) => 
        [
          formatDate(date),
          event,
          ...positions.map(position => assignments[position] || '-')
        ].join(',')
      )
    ].join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', `${rotaName || 'rota'}.csv`);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const toggleAssignmentCounts = () => {
    setShowAssignmentCounts(!showAssignmentCounts);
  };

  if (!rota) {
    return <div className="loading">Loading rota data...</div>;
  }

  return (
    <div className="view-rota-page">
      <h1>{rotaName || 'View Rota'}</h1>
      <div className="rota-table-container">
        <table className="rota-table">
          <thead>
            <tr>
              <th className="date-header">Date</th>
              <th className="event-header">Event</th>
              {positions.map(position => (
                <th key={position} className="position-header">{position}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {groupedAndSortedRota.map(({ date, event, assignments }, index, array) => (
              <tr key={`${date}-${event}`} className={index === 0 || date !== array[index - 1].date ? 'new-date' : ''}>
                {(index === 0 || date !== array[index - 1].date) && (
                  <td rowSpan={array.filter(item => item.date === date).length} className="date-cell">{formatDate(date)}</td>
                )}
                <td className="event-cell">{event}</td>
                {positions.map(position => (
                  <td key={`${date}-${event}-${position}`} className="assignment-cell">
                    {isEditing ? (
                      <select
                        value={editedRota[`${date} ${event}`]?.[position] || '-'}
                        onChange={(e) => handleAssignmentChange(date, event, position, e.target.value)}
                      >
                        <option value="-">-</option>
                        {getQualifiedMembers(position).map(member => (
                          <option key={member.id} value={member.name}>{member.name}</option>
                        ))}
                      </select>
                    ) : (
                      assignments[position] || '-'
                    )}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="rota-actions">
        {isEditing ? (
          <>
            <button onClick={handleSave} className="save-button">Save Changes</button>
            <button onClick={handleCancel} className="cancel-button">Cancel</button>
            <button onClick={checkForConflicts} className="conflict-button">Check Conflicts</button>
          </>
        ) : (
          <button onClick={handleEdit} className="edit-button">Edit Rota</button>
        )}
        <button onClick={exportToCSV} className="export-button">Export to CSV</button>
        <button onClick={toggleAssignmentCounts} className="count-button">Count Assignments</button>
      </div>
      {showAssignmentCounts && (
        <div className="assignment-counts-popup">
          <div className="assignment-counts-content">
            <h2>Assignment Counts</h2>
            <ul>
              {Object.entries(assignmentCounts)
                .sort((a, b) => b[1] - a[1])
                .map(([name, count]) => (
                  <li key={name}>{name}: {count}</li>
                ))}
            </ul>
            <button onClick={toggleAssignmentCounts}>Close</button>
          </div>
        </div>
      )}
      {showConflicts && (
        <div className="conflicts-popup">
          <div className="conflicts-content">
            <h2>Conflicts Found</h2>
            {conflicts.length === 0 ? (
              <p>No conflicts found in the rota.</p>
            ) : (
              <ul>
                {conflicts.map((conflict, index) => (
                  <li key={index}>
                    {conflict.event}: {conflict.names.join(', ')}
                  </li>
                ))}
              </ul>
            )}
            <button onClick={() => setShowConflicts(false)}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
}

export default ViewRotaPage;