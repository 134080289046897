import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useTeam } from '../context/TeamContext';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import './EditMemberPage.css';

function EditMemberPage() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { teamMembers, updateTeamMember } = useTeam();
  const [name, setName] = useState('');
  const [qualifications, setQualifications] = useState({
    JimmyJib: false,
    Moving: false,
    Switcher: false,
    Title: false,
    Animasi: false,
    Foto: false,
    Lighting: false,
    Socmed: false
  });
  const [eventPreferences, setEventPreferences] = useState({
    IBR1: false,
    IBR2: false,
    IBR3: false,
    IBR4: false,
    NOW: false,
    WBI: false,
    Wedding: false,
    'Special Events': false
  });
  const [unavailableDates, setUnavailableDates] = useState({});
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [active, setActive] = useState(true);

  const qualificationOrder = ['JimmyJib', 'Moving', 'Switcher', 'Title', 'Animasi', 'Foto', 'Lighting', 'Socmed'];
  const eventOrder = ['IBR1', 'IBR2', 'IBR3', 'IBR4', 'NOW', 'WBI', 'Wedding', 'Special Events'];

  useEffect(() => {
    const member = teamMembers.find(m => m.id === id);
    if (member) {
      setName(member.name);
      setQualifications(member.qualifications);
      setEventPreferences(member.eventPreferences || {
        IBR1: false,
        IBR2: false,
        IBR3: false,
        IBR4: false,
        NOW: false,
        WBI: false,
        Wedding: false,
        'Special Events': false
      });
      setUnavailableDates(member.unavailableDates || {});
      setActive(member.active !== undefined ? member.active : true);
    }
  }, [id, teamMembers]);

  const handleQualificationChange = (event) => {
    setQualifications({
      ...qualifications,
      [event.target.name]: event.target.checked
    });
  };

  const handleEventPreferenceChange = (event) => {
    setEventPreferences({
      ...eventPreferences,
      [event.target.name]: event.target.checked
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    updateTeamMember(id, { name, qualifications, eventPreferences, unavailableDates, active });
    navigate('/team-members');
  };

  const addUnavailableDate = () => {
    const dateString = selectedDate.toISOString().split('T')[0];
    setUnavailableDates(prev => ({
      ...prev,
      [dateString]: eventOrder.reduce((acc, event) => {
        acc[event] = false;
        return acc;
      }, {})
    }));
  };

  const removeUnavailableDate = (dateToRemove) => {
    setUnavailableDates(prev => {
      const { [dateToRemove]: removed, ...rest } = prev;
      return rest;
    });
  };

  const handleUnavailableEventChange = (date, event, isChecked) => {
    setUnavailableDates(prev => ({
      ...prev,
      [date]: {
        ...prev[date],
        [event]: isChecked
      }
    }));
  };

  return (
    <div className="page-container edit-member-page">
      <h1>UPDATE {name.toUpperCase()}</h1>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="name">Name:</label>
          <input
            type="text"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </div>

        <div className="form-group">
          <h2>Qualifications:</h2>
          <div className="checkbox-group">
            {qualificationOrder.map((qual) => (
              <div key={qual} className="checkbox-item">
                <input
                  type="checkbox"
                  id={qual}
                  name={qual}
                  checked={qualifications[qual]}
                  onChange={handleQualificationChange}
                />
                <label htmlFor={qual}>{qual}</label>
              </div>
            ))}
          </div>
        </div>

        <div className="form-group">
          <h2>Events:</h2>
          <div className="checkbox-group">
            {eventOrder.map((event) => (
              <div key={event} className="checkbox-item">
                <input
                  type="checkbox"
                  id={event}
                  name={event}
                  checked={eventPreferences[event]}
                  onChange={handleEventPreferenceChange}
                />
                <label htmlFor={event}>{event}</label>
              </div>
            ))}
          </div>
        </div>

        <div className="form-group">
          <h2>Unavailable Events:</h2>
          <div className="date-picker-container">
            <DatePicker
              selected={selectedDate}
              onChange={date => setSelectedDate(date)}
              dateFormat="yyyy-MM-dd"
            />
            <button type="button" onClick={addUnavailableDate}>Add Unavailable Date</button>
          </div>
          <ul className="unavailable-dates-list">
            {Object.entries(unavailableDates).map(([date, events]) => (
              <li key={date}>
                <span>{date}</span>
                <div className="event-checkboxes">
                  {eventOrder.map(event => (
                    <div key={event} className="checkbox-item">
                      <input
                        type="checkbox"
                        id={`${date}-${event}`}
                        checked={events[event]}
                        onChange={(e) => handleUnavailableEventChange(date, event, e.target.checked)}
                      />
                      <label htmlFor={`${date}-${event}`}>{event}</label>
                    </div>
                  ))}
                </div>
                <button type="button" onClick={() => removeUnavailableDate(date)}>Remove</button>
              </li>
            ))}
          </ul>
        </div>

        <div className="form-group">
          <h2>Status:</h2>
          <div className="radio-group">
            <div className="radio-item">
              <input
                type="radio"
                id="active"
                name="status"
                value="active"
                checked={active}
                onChange={() => setActive(true)}
              />
              <label htmlFor="active">Active</label>
            </div>
            <div className="radio-item">
              <input
                type="radio"
                id="inactive"
                name="status"
                value="inactive"
                checked={!active}
                onChange={() => setActive(false)}
              />
              <label htmlFor="inactive">Inactive</label>
            </div>
          </div>
        </div>

        <button type="submit" className="update-button">UPDATE</button>
      </form>
    </div>
  );
}

export default EditMemberPage;