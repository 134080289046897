import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { db } from '../firebase';
import { collection, onSnapshot, deleteDoc, doc, query, orderBy } from 'firebase/firestore';
import './SchedulesPage.css';

function SchedulesPage() {
  const [schedules, setSchedules] = useState([]);
  const [confirmDelete, setConfirmDelete] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const rotasRef = collection(db, 'rotas');
    const q = query(rotasRef, orderBy('createdAt', 'desc')); // Sort by createdAt in descending order
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const schedulesArray = snapshot.docs.map(doc => {
        const data = doc.data();
        if (data.rota) {
          Object.keys(data.rota).forEach(eventKey => {
            if (!data.rota[eventKey]['Socmed']) {
              data.rota[eventKey]['Socmed'] = 'Unassigned';
            }
          });
        }
        return {
          id: doc.id,
          ...data
        };
      });
      setSchedules(schedulesArray);
    }, (error) => {
      console.error("Error fetching schedules:", error);
    });

    return () => unsubscribe();
  }, []);

  const handleViewRota = (scheduleId) => {
    navigate(`/view-rota/${scheduleId}`);
  };

  const handleDeleteRota = (scheduleId) => {
    setConfirmDelete(scheduleId);
  };

  const confirmDeleteRota = async () => {
    if (confirmDelete) {
      try {
        const rotaRef = doc(db, 'rotas', confirmDelete);
        await deleteDoc(rotaRef);
        setConfirmDelete(null);
      } catch (error) {
        console.error('Error deleting rota:', error);
        alert('Failed to delete rota. Please try again.');
      }
    }
  };

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  return (
    <div className="page-container schedules-page">
      <h1>Saved Schedules</h1>
      {schedules.length === 0 ? (
        <p className="no-schedules">No saved schedules yet.</p>
      ) : (
        <ul className="schedules-list">
          {schedules.map((schedule) => (
            <li key={schedule.id} className="schedule-item">
              <div className="schedule-info">
                <h2>{schedule.name}</h2>
                <div className="schedule-details">
                  <span className="created-date">Created: {formatDate(schedule.createdAt)}</span>
                  <span className="created-by">By: {schedule.createdBy}</span>
                </div>
              </div>
              <div className="button-group">
                <button 
                  className="view-button"
                  onClick={() => handleViewRota(schedule.id)}
                >
                  View
                </button>
                <button 
                  className="delete-btn"
                  onClick={() => handleDeleteRota(schedule.id)}
                >
                  Delete
                </button>
              </div>
              
              {confirmDelete === schedule.id && (
                <div className="confirm-dialog">
                  <p>Are you sure you want to delete this schedule?</p>
                  <div className="confirm-buttons">
                    <button 
                      className="confirm-delete"
                      onClick={confirmDeleteRota}
                    >
                      Yes, Delete
                    </button>
                    <button 
                      className="cancel-delete"
                      onClick={() => setConfirmDelete(null)}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              )}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

export default SchedulesPage;