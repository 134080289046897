import React, { useState, useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTeam } from '../context/TeamContext';
import { useAuth } from '../context/AuthContext';
import './TeamMembersPage.css';

function TeamMembersPage() {
  const { teamMembers, deleteTeamMember, removeAllUnavailableEvents } = useTeam();
  const { loading: authLoading } = useAuth();
  const [memberToDelete, setMemberToDelete] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [showDateRemoveConfirm, setShowDateRemoveConfirm] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [filter, setFilter] = useState('all');
  const navigate = useNavigate();

  const qualificationOrder = ['JimmyJib', 'Moving', 'Switcher', 'Title', 'Animasi', 'Foto', 'Lighting', 'Socmed'];
  const eventOrder = ['IBR1', 'IBR2', 'IBR3', 'IBR4', 'NOW', 'WBI', 'Wedding', 'Special Events'];

  const filteredMembers = useMemo(() => {
    if (!teamMembers) return [];
    return teamMembers.filter(member => {
      const nameMatch = member.name.toLowerCase().includes(searchTerm.toLowerCase());
      
      switch (filter) {
        case 'inactive':
          return nameMatch && !member.active;
        case 'socmed':
          return nameMatch && member.qualifications.Socmed;
        default:
          return nameMatch;
      }
    });
  }, [teamMembers, searchTerm, filter]);

  const handleDelete = (member) => {
    setMemberToDelete(member);
  };

  const confirmDelete = () => {
    if (memberToDelete) {
      deleteTeamMember(memberToDelete.id);
      setMemberToDelete(null);
    }
  };

  const handleEdit = (member) => {
    navigate(`/edit-member/${member.id}`);
  };

  const handleRemoveDates = () => {
    setShowDateRemoveConfirm(true);
  };

  const confirmRemoveDates = () => {
    if (typeof removeAllUnavailableEvents === 'function') {
      removeAllUnavailableEvents();
    } else {
      console.error("removeAllUnavailableEvents is not a function");
    }
    setShowDateRemoveConfirm(false);
    setShowSuccessMessage(true);
    setTimeout(() => {
      setShowSuccessMessage(false);
    }, 3000);
  };

  const getPreferredEvents = (eventPreferences) => {
    if (!eventPreferences) return 'None';
    return eventOrder
      .filter(event => eventPreferences[event])
      .join(', ') || 'None';
  };

  const getQualifications = (qualifications) => {
    if (!qualifications) return 'None';
    return qualificationOrder
      .filter(qual => qualifications[qual])
      .join(', ') || 'None';
  };

  const getUnavailableEventsString = (unavailableDates) => {
    if (!unavailableDates || Object.keys(unavailableDates).length === 0) return 'None';
    return Object.entries(unavailableDates).map(([date, events]) => {
      const unavailableEvents = eventOrder
        .filter(event => events[event])
        .join(', ');
      return `${date}: ${unavailableEvents || 'None'}`;
    }).join('; ');
  };

  if (authLoading) {
    return <div className="loading">Loading...</div>;
  }

  return (
    <div className="team-members-page">
      <h1>TEAM MEMBERS</h1>
      <div className="sub-header">
        <div className="member-count">
          Total Registered Members: {teamMembers.length}
        </div>
        <div className="button-group">
          <div className="remove-dates-container">
            <button onClick={handleRemoveDates} className="remove-dates-btn">Remove Unavailable Events</button>
            {showDateRemoveConfirm && (
              <div className="confirm-dialog">
                <p>Are you sure you want to remove all unavailable events for all members?</p>
                <div className="confirm-actions">
                  <button onClick={confirmRemoveDates} className="confirm-delete">Yes, Remove</button>
                  <button onClick={() => setShowDateRemoveConfirm(false)} className="cancel-delete">Cancel</button>
                </div>
              </div>
            )}
          </div>
          <Link to="/add-member" className="add-member-btn">
            ADD MEMBER
          </Link>
        </div>
      </div>

      <div className="search-filter-container">
        <div className="search-bar">
          <input
            type="text"
            placeholder="Search members..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        <div className="filter-buttons">
          <button 
            className={`filter-btn ${filter === 'all' ? 'active' : ''}`}
            onClick={() => setFilter('all')}
          >
            All
          </button>
          <button 
            className={`filter-btn ${filter === 'inactive' ? 'active' : ''}`}
            onClick={() => setFilter('inactive')}
          >
            Inactive
          </button>
          <button 
            className={`filter-btn ${filter === 'socmed' ? 'active' : ''}`}
            onClick={() => setFilter('socmed')}
          >
            Socmed
          </button>
        </div>
      </div>

      {filteredMembers.length > 0 ? (
        <ul className="member-list">
          {filteredMembers.map((member) => (
            <li key={member.id} className={`member-item ${!member.active ? 'inactive' : ''}`}>
              <div className="member-info">
                <h2>{member.name}</h2>
                <p>
                  <span className="info-label">Qualifications:</span>
                  <span className="info-value">
                    {getQualifications(member.qualifications)}
                  </span>
                </p>
                <p>
                  <span className="info-label">Events:</span>
                  <span className="info-value">{getPreferredEvents(member.eventPreferences)}</span>
                </p>
                <p>
                  <span className="info-label">Status:</span>
                  <span className={`info-value ${!member.active ? 'inactive-status' : 'active-status'}`}>
                    {member.active ? 'Active' : 'Inactive'}
                  </span>
                </p>
                <p>
                  <span className="info-label">Unavailable Events:</span>
                  <span className="info-value">
                    {getUnavailableEventsString(member.unavailableDates)}
                  </span>
                </p>
              </div>
              <div className="member-actions">
                <button onClick={() => handleEdit(member)} className="edit-btn">Edit</button>
                <div className="delete-container">
                  <button onClick={() => handleDelete(member)} className="delete-btn">Delete</button>
                  {memberToDelete && memberToDelete.id === member.id && (
                    <div className="confirm-dialog">
                      <p>Are you sure you want to delete {member.name}?</p>
                      <div className="confirm-actions">
                        <button onClick={confirmDelete} className="confirm-delete">Yes, Delete</button>
                        <button onClick={() => setMemberToDelete(null)} className="cancel-delete">Cancel</button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </li>
          ))}
        </ul>
      ) : (
        <p className="no-members">
          {searchTerm ? "No matching members found." : "No team members added yet."}
        </p>
      )}

      {showSuccessMessage && (
        <div className="modal-overlay">
          <div className="modal-content success-message">
            <p>All unavailable events have been removed successfully!</p>
          </div>
        </div>
      )}
    </div>
  );
}

export default TeamMembersPage;